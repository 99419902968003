import axios from "axios";
import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {


  constructor() {
    super()

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      subject: "",
      message: "",
      isError: false,
      errorMessage: '',
      loading: false,
      isSuccess: false,
      successMessage: '',
    }


  }

  nameHandle = (name) => this.setState({ name })
  emailHandle = (email) => this.setState({ email })
  phoneNumberHandle = (phoneNumber) => this.setState({ phoneNumber })
  subjectHandle = (subject) => this.setState({ subject })
  messageHandle = (message) => this.setState({ message })


  handleSubmit = async (event) => {
    event.preventDefault()
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    const { name, email, phoneNumber, subject, message, } = this.state

    this.setState({ loading: false, isError: false })

    if (name.trim() === '') {
      return this.setState({ isError: true, errorMessage: "Name is Required" })
    }
    if (email.trim() === '') {
      return this.setState({ isError: true, errorMessage: "Email is Required" })
    }
    if (!emailPattern.test(email)) {
      return this.setState({ isError: true, errorMessage: "Email is not valid" })
    }
    if (phoneNumber.trim() === '') {
      return this.setState({ isError: true, errorMessage: "Phone Number is Required" })
    }
    if (subject.trim() === '') {
      return this.setState({ isError: true, errorMessage: "Subject  is Required" })
    }
    if (message.trim() === '') {
      return this.setState({ isError: true, errorMessage: "Message is Required" })
    }

    this.setState({ loading: true, isError: false })

    var data = {
      name,
      email,
      subject,
      message,
      phoneNumber
    }


    const serviceUrl = "https://webform.riderpro.io/v1/website/form";

    axios.post(serviceUrl, data)
      .then((resp) => {
        console.log(resp, 'resp');
        this.setState({ loading: false, isError: false, errorMessage: "", isSuccess: true, successMessage: "Your message has been sent to the Rider Pro team. Thank you!", name: "", email: "", phoneNumber: "", subject: "", message: "", })
      })
      .catch((error) => {
        console.log(error?.response?.data?.message, 'error');
        this.setState({ loading: false, isError: true, errorMessage: error?.response?.data?.message ?? "Can't submit at this time", })
      })
  }

  render() {
    const { loading, isError, isSuccess, errorMessage, successMessage, name, email, subject, message, phoneNumber } = this.state
    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>
            <div className="ten columns" style={{ paddingTop: 20 }}>
              <h2 style={{ color: "white" }} className="lead">Join the Wishlist to Download</h2>
              <p style={{ color: "white" }}>You will be notified via email whenever the Rider Pro Experience is ready for download.</p>
            </div>
          </div>
        </Fade>

        <div className="row">

          <Slide left duration={1000}>
            <div className="eight columns">
              <form action="" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      size="35"
                      id="contactName"
                      name="contactName"
                      required
                      placeholder="Your name"
                      value={name}
                      onChange={(event) => this.nameHandle(event.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      required
                      placeholder="Your email address"
                      value={email}
                      onChange={(event) => this.emailHandle(event.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactPhoneNumber">
                      Phone Number <span className="required">*</span>
                    </label>

                    <div className="country-container">
                      <img src='images/flag.jpg' alt="Country Flag" className="country-flag" />
                      <span className="country-code">+92</span>
                    </div>

                    <input
                      type="text"
                      size="35"
                      id="contactPhoneNumber"
                      name="contactPhoneNumber"
                      maxLength="10"
                      required
                      placeholder="333-1234567"
                      value={phoneNumber}
                      onChange={(event) => this.phoneNumberHandle(event.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">Subject <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      required
                      placeholder="Subject"
                      value={subject}
                      onChange={(event) => this.subjectHandle(event.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      cols="2"
                      rows="2"
                      id="contactMessage"
                      name="contactMessage"
                      required
                      placeholder="Your message"
                      value={message}
                      onChange={(event) => this.messageHandle(event.target.value)}
                    ></textarea>
                  </div>

                  <div>
                    {!loading && <button onClick={this.handleSubmit} className="submit"  >Submit</button>}
                    {loading && <div className="submit"><img alt="" src="images/loader.gif" /></div>}


                  </div>
                </fieldset>
              </form>

              {isError && <div style={{ color: "red" }}>{errorMessage}</div>}
              {isSuccess && <div style={{ color: "green" }}>
                <i className="fa fa-check"></i> {successMessage}
                {/* Your message was sent, thank you! */}
                <br />
              </div>}
            </div>
          </Slide>


          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <img
                  className="profile-pics"
                  src={"images/app_white_icon.png"}
                  alt="bike"

                />
                <div className="address">
                  {/* <h2 style={{ color: "white" }}>About Us</h2> */}
                  <p style={{ color: "white" }}>Rider Pro is the ultimate app for motorcycle enthusiasts. Whether you need to find a mechanic, a charging station, or a genuine product, Rider Pro has you covered. You can also connect with other riders, chat with them, and share your experiences on our forum. Rider Pro is more than just an app, it's a community. Download it today and join the ride.</p>
                  <div className="columns contact-details" style={{ padding: 0 }}>
                    {/* <h2 style={{ color: "white" }}>Contact Details</h2> */}
                    <p style={{ color: "white" }} className="address">
                      <span>Rider Pro 2024 ©</span>
                      <br />
                      <span>A product of Crownsoft, Crown Group of Companies.</span>
                    </p>
                  </div>
                </div>
              </div>


            </aside>
          </Slide>






        </div>

      </section>
    );
  }
}

export default Contact;
