import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    // if (!this.props.data) return null;

    // const skillmessage = this.props.data.skillmessage;
    // const education = this.props.data.education.map(function (education) {
    //   return (
    //     <div key={education.school}>
    //       <h3>{education.school}</h3>
    //       <p className="info">
    //         {education.degree} <span>&bull;</span>
    //         <em className="date">{education.graduated}</em>
    //       </p>
    //       <p>{education.description}</p>
    //     </div>
    //   );
    // });

    // const work = this.props.data.work.map(function (work) {
    //   return (
    //     <div key={work.company}>
    //       <h3>{work.company}</h3>
    //       <p className="info">
    //         {work.title}
    //         <span>&bull;</span> <em className="date">{work.years}</em>
    //       </p>
    //       <p>{work.description}</p>
    //     </div>
    //   );
    // });

    // const skills = this.props.data.skills.map((skills) => {
    //   const backgroundColor = this.getRandomColor();
    //   const className = "bar-expand " + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className}></span>
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            {/* <div className="three columns header-col">
              <img className="profile-pic" src={"images/app_images/1.png"} alt="app" />
            </div> */}
            <div className="twelve columns main-col">
              <div className="row item">
                <div className="twelve columns center text-center">
                  <div >
                    <h3>Rider Pro  All-in-One Solution</h3>
                    <p>Embark on a complete automotive journey with our all-in-one application, featuring a nearby mechanic and retailer search for quick access, integrated chat for real-time communication, secure item authentication with QR codes, a rider forum for community engagement, and information on charging networks, providing a comprehensive solution for all your automotive needs in one convenient platform.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>


        <Slide right duration={1300}>
          <div className="row work">
            <div className="nine columns main-col">
              <img className="profile-pic" src={"images/newImages/1.jpeg"} alt="app" style={{ width: 400 }} />
            </div>

            <div className=" three columns header-col">
              <div >
                <h3>Scan QR</h3>
                <p>Scan Smart QR Codes and get rewards; authenticate original parts like never before and get your trust validation on the go! Find Nearby Mechanics and Charging Stations and get started with availing services like never before.</p>

              </div>
            </div>
          </div>
        </Slide>

        <Slide right duration={1300}>
          <div className="row work">



            <div className="five columns header-col">
              <div >
                <h3>Nearby Search</h3>
                <p>Find Nearby Mechanics and Charging Stations and navigate to the end point with ease.</p>

              </div>
            </div>

            <div className="seven columns main-col right text-right">
              <img className="profile-pic" src={"images/newImages/4.png"} alt="app" style={{ height: 400 }} />

            </div>

          </div>
        </Slide>


        <Slide right duration={1300}>
          <div className="row work">


            <div className="seven columns main-col">
              <img className="profile-pic" src={"images/newImages/5.png"} alt="app" style={{ height: 400 }} />
            </div>

            <div className="five columns header-col">
              <div >
                <h3>Socially Connected</h3>
                <p>Stay connected with your favorite mechanics and communicate with them seamlessly with push notifications and messaging service. Navigate to your end point with easy interface and also book your mechanic beforehand. Keep a log of your communication with RiderPro's intelligent messaging system which tracks all your QR scans at the mechanic to redeem product warranties later on.</p>

              </div>
            </div>
          </div>
        </Slide>



        {/* <Slide right duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </Slide> */}


      </section>
    );
  }
}

export default Resume;