import React from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import App from './App'
import HomePage from './homePage'
import PrivacyAndPolicy from './privacyAndPolicy'
import OptionalPolicyIOS from './optionPolicyIos'
const { PUBLIC_URL } = process.env

const AppRoutes = () => {
    return (
        <BrowserRouter basename={PUBLIC_URL} >

            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/privacy-policy' element={<PrivacyAndPolicy />} />
                <Route path='/user-policy' element={<OptionalPolicyIOS />} />
                
            </Routes>

        </BrowserRouter>

    )
}

export { AppRoutes }
