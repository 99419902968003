import React, { Component } from "react";
// import Zmage from "react-zmage";
import Fade from "react-reveal";

// let id = 0;
class Portfolio extends Component {
  render() {
    // if (!this.props.data) return null;

    // const projects = this.props.data.projects.map(function (projects) {
    //   let projectImage = "images/portfolio/" + projects.image;

    //   return (
    //     <div key={id++} className="columns portfolio-item">
    //       <div className="item-wrap">
    //         <Zmage alt={projects.title} src={projectImage} />
    //         <div style={{ textAlign: "center" }}>{projects.title}</div>
    //       </div>
    //     </div>
    //   );
    // });

    return (
      <section id="portfolio">
        <Fade left duration={1000}  >
          <div className="rows">
            <div className="twelve columns collapsed">
              {/* <h1>Check Out Some of our main features.</h1> */}
              <img className="profile-pic" src={"images/newImages/3.png"} alt="" />
              {/* <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >

                <div className="columns portfolio-item">
                  <div className="item-wrap">
                    <Zmage alt={"img"} src="images/app_images/1.png" />
                    <div style={{ textAlign: "center" }}>Android Application</div>
                  </div>
                </div>

                <div className="columns portfolio-item">
                  <div className="item-wrap">
                    <Zmage alt={"img"} src="images/app_images/2.png" />
                    <div style={{ textAlign: "center" }}>Android Application</div>
                  </div>
                </div>

                <div className="columns portfolio-item">
                  <div className="item-wrap">
                    <Zmage alt={"img"} src="images/app_images/3.png" />
                    <div style={{ textAlign: "center" }}>Android Application</div>
                  </div>
                </div>

                <div className="columns portfolio-item">
                  <div className="item-wrap">
                    <Zmage alt={"img"} src="images/app_images/4.png" />
                    <div style={{ textAlign: "center" }}>Android Application</div>
                  </div>
                </div>





              </div> */}
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
