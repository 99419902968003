import React, { Component } from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
// import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";
import { AppRoutes } from "./routes";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            resumeData: {}
        };
    }

    getResumeData() {
        // $.ajax({
        //   url: "./resumeData.json",
        //   dataType: "json",
        //   cache: false,
        //   success: function (data) {
        //     this.setState({ resumeData: data });
        //   }.bind(this),
        //   error: function (xhr, status, err) {
        //     console.log(err);
        //     alert(err);
        //   }
        // });
    }

    componentDidMount() {
        // this.getResumeData();
    }

    render() {
        return (
            <div className="App" >
                <Header />

                <Resume />
                <Portfolio />
                <Contact />
                {/* <About /> */}
                <Footer />
            </div>
        );
    }
}

export default HomePage;