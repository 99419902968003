import React, { Component } from "react";
import "./App.css";
export default class OptionalPolicyIOS extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div style={{ padding: 15, background: "white" }} >
                <img alt="" className="responsive-headline" src="images/app_icon.png" style={{ resize: "contain" }} />
                <h1>Privacy Choices for Rider Pro</h1>

                <p>At Rider Pro, we are committed to protecting your privacy and providing you with clear options regarding how your personal information is used. This page explains your rights and choices related to your personal data and how you can manage your privacy preferences within Rider Pro.</p>

                <h2>Managing Your Data</h2>
                <p><strong>Access, Correct, or Delete Your Information</strong>: You have the right to access, correct, or delete the personal information we collect about you. To exercise these rights, please contact us at <a href="mailto:info@riderpro.io">info@riderpro.io</a>. We will respond to your request within a reasonable timeframe.</p>

                <h1>Apple, Google, and Facebook Login Policy</h1>

                <p>At Rider Pro, we offer the option to log in using Apple, Google, and Facebook accounts for your convenience. Your privacy and data security are important to us. This policy outlines how we handle your information when you choose to log in using these platforms.</p>

                <h2>Apple Login</h2>
                <p>When you log in using your Apple ID, we collect the following information:</p>
                <ul>
                    <li>Your Apple ID</li>
                    <li>Email address associated with your Apple account</li>
                    <li>Name</li>
                </ul>
                <p><strong>Privacy Policy:</strong> For more information about how Apple handles your data, please refer to <a href="https://www.apple.com/legal/privacy/" target="_blank">Apple's Privacy Policy</a>.</p>

                <h2>Google Login</h2>
                <p>When you log in using your Google account, we collect the following information:</p>
                <ul>
                    <li>Your Google ID</li>
                    <li>Email address associated with your Google account</li>
                    <li>Profile picture</li>
                    <li>Name</li>
                </ul>
                <p><strong>Privacy Policy:</strong> For more information about how Google handles your data, please refer to <a href="https://policies.google.com/privacy" target="_blank">Google's Privacy Policy</a>.</p>

                <h2>Facebook Login</h2>
                <p>When you log in using your Facebook account, we collect the following information:</p>
                <ul>
                    <li>Your Facebook ID</li>
                    <li>Email address associated with your Facebook account</li>
                    <li>Profile picture</li>
                    <li>Name</li>
                </ul>
                <p><strong>Privacy Policy:</strong> For more information about how Facebook handles your data, please refer to <a href="https://www.facebook.com/policy.php" target="_blank">Facebook's Data Policy</a>.</p>



                <h1>Account Deletion Policy</h1>

                <p>At Rider Pro, we understand that you may want to delete your account for various reasons. This policy outlines the process and implications of deleting your Rider Pro account.</p>

                <h2>Requesting Account Deletion</h2>
                <p>To request the deletion of your Rider Pro account, please contact our support team at <a href="mailto:support@riderpro.io">support@riderpro.io</a>. We will guide you through the account deletion process and verify your identity for security purposes.</p>

                <h2>Implications of Account Deletion</h2>
                <ul>
                    <li><strong>Loss of Data</strong>: Deleting your account will result in the permanent loss of all data associated with your account, including ride history, preferences, and saved information.</li>
                    <li><strong>No Recovery</strong>: Once your account is deleted, it cannot be recovered. Please ensure that you have backed up any important data before proceeding with account deletion.</li>
                    <li><strong>Subscriptions and Payments</strong>: If you have an active subscription or pending payments, they will be canceled upon account deletion. Refunds may be applicable according to our refund policy.</li>
                </ul>

                <h2>Confirming Account Deletion</h2>
                <p>Before proceeding with account deletion, we will confirm your decision and provide you with information about the consequences of deletion. You may be asked to confirm your request through a verification process.</p>


                <h2>Opt-Out of Data Collection</h2>
                <ul>
                    <li><strong>Google Analytics</strong>: To opt-out of Google Analytics data collection, you can install the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analytics Opt-out Browser Add-on</a>.</li>
                    <li><strong>Marketing Communications</strong>: You can opt-out of receiving marketing emails from us by clicking the "unsubscribe" link in any email you receive or by contacting us at <a href="mailto:info@riderpro.io">info@riderpro.io</a>.</li>
                </ul>

                <h2>Location Data</h2>
                <p>You can manage your location data preferences through your device settings. To disable location services:</p>
                <ul>
                    <li><strong>iOS</strong>: Go to Settings &gt; Privacy &gt; Location Services and turn off location services for Rider Pro.</li>
                </ul>

                <h2>Microphone Access</h2>
                <p>Rider Pro requires microphone access to enable QR code scanning. You can manage microphone access through your device settings:</p>
                <ul>
                    <li><strong>iOS</strong>: Go to Settings &gt; Privacy &gt; Microphone and turn off microphone access for Rider Pro.</li>
                </ul>

                <h2>Contacts Access</h2>
                <p>Rider Pro requires access to your contacts to help you easily connect with friends and colleagues within the app. You can manage contacts access through your device settings:</p>
                <ul>
                    <li><strong>iOS</strong>: Go to Settings &gt; Privacy &gt; Contacts and turn off contacts access for Rider Pro.</li>
                </ul>

                <h2>Photo Library Access</h2>
                <p>Rider Pro requires access to your photo library to save photos to your gallery for your convenience. You can manage photo library access through your device settings:</p>
                <ul>
                    <li><strong>iOS</strong>: Go to Settings &gt; Privacy &gt; Photos and turn off photo library access for Rider Pro.</li>
                </ul>

                <h2>Cookies and Tracking Technologies</h2>
                <p>You can manage your cookie preferences through your browser settings. Most browsers allow you to block cookies or notify you when a cookie is being placed on your device. Please refer to your browser's help section for instructions on how to manage cookies.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about your privacy choices or how we handle your personal data, please contact us at:</p>
                <p><strong>Email</strong>: <a href="mailto:info@riderpro.io">Info@riderpro.io</a>
                </p>
            </div>



        )
    }
}

